import React from 'react';


import CheckboxField from "../../page-components/CheckboxField";
import ChoiceField from "../../page-components/ChoiceField";
import DateField from "../../page-components/DateField";
import NumberplateField from "../../page-components/NumberplateField";
import ProductChoiceField from "../../page-components/ProductChoiceField";
import ProductChoiceListField from "../../page-components/ProductChoiceListField";
import ProductSuggestField from "../../page-components/ProductSuggestField";
import RichText from "../../page-components/RichText";
import Text from "../../page-components/Text";
import Title from "../../page-components/Title";
import TextField from "../../page-components/TextField";
import AssetTypeField from "../../page-components/AssetTypeField";
import SelectField from "../../page-components/SelectField";
import SelectCodePostalField from "../../page-components/SelectCodePostalField";
import Section from "../../page-components/Section";
import FleetField from "../../page-components/FleetField";
import AssetValueField from "../../page-components/AssetValueField";
import Button from "../../page-components/Button";
import FleetMatrix from "../../page-components/FleetMatrix";
import BasketSummary from "../../page-components/BasketSummary";
import FoundedCarField from "../../page-components/FoundedCarField";
import FormulaMatrix from "../../page-components/FormulaMatrix";
import FoundedCarCardField from '../../page-components/FoundedCarCardField';
import AddressSelectField from '../../page-components/AddressSelectField';


const componentTypes = {
    'form.checkbox-field' : CheckboxField,
    'form.choice-field': ChoiceField,
    'form.date-field': DateField,
    'form.numberplate-field': NumberplateField,
    'form.product-choice-field': ProductChoiceField,
    'form.product-choice-list-field': ProductChoiceListField,
    'form.product-suggest-field': ProductSuggestField,
    'form.rich-text': RichText,
    'form.text': Text,
    'form.button': Button,
    'form.text-field': TextField,
    'form.select-field': SelectField,
    'form.code-postal-select-field': SelectCodePostalField,
    'form.founded-car-field': FoundedCarField,
    'form.asset-type': AssetTypeField,
    'form.title': Title,
    'form.fleet-field': FleetField,
    'form.section': Section,
    'form.asset-value-field': AssetValueField,
    'form.asset-type-field': AssetTypeField,
    'form.basket-summary': BasketSummary,
    'form.fleet-matrix': FleetMatrix,
    'form.formula-matrix': FormulaMatrix,
    'form.founded-car-card-field': FoundedCarCardField,
    'form.address-select-field': AddressSelectField,
}

const DynamicComponent = ({registry, component}) => {

    let {__component, id, events, ...properties} = component;

    events = (events || []).reduce((map, e) => {
        if(!map.has(e.event)) {
            map.set(e.event, []);
        }
        map.get(e.event).push(e);
        return map;
    }, new Map());

    if(!componentTypes[__component]) {
        console.error("Unknown component : "+__component);
        return <></>
    }

    return React.createElement(componentTypes[__component], {
        ...properties,
        events,
        registry
    });

}


export default DynamicComponent