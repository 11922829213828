import React, { useContext, useState } from "react";
import { useTrackableState } from "../service/PageService";
import { BasketContext } from "../context";
import { Box, Button, Grid, Modal, Stack } from "@mui/material";

import "./FleetMatrix.scss";
import ReactMarkdown from "react-markdown";

const Component = ({ registry, events, choice, options, asset, ...args }) => {
  const [title, setTitle] = useTrackableState(args.title || "");
  const [subtitle, setSubtitle] = useTrackableState(args.subtitle || "");
  const [visible, setVisible] = useTrackableState(true);
  const [readonly, setReadonly] = useState(!!args.readonly);
  const [showModal, setShowModal] = useState(null);

  const { navigationState, basket, product } = useContext(BasketContext);

  if (!asset) return <></>;

  const assetDef = product.fleet.find(
    (f) => f.asset.code === asset.code
  )?.asset;

  if (!assetDef) return <></>;

  const assetChoices = product.choices.filter(
    (choice) => choice.asset && asset && choice.asset.code === asset.code
  );
  const firstOptions =
    assetChoices.find((c) => c.code === choice)?.options || [];

  const columnChoices = assetChoices.filter(
    (c) => options.split("\n").indexOf(c.code) >= 0
  );

  if (args.code) {
    const api = {};

    Object.defineProperties(api, {
      visible: {
        get: () => visible,
        set: (v) => setVisible(v),
      },
      title: {
        get: () => title,
        set: (v) => setTitle(v),
      },
      subtitle: {
        get: () => subtitle,
        set: (v) => setSubtitle(v),
      },
    });

    registry.set(args.code, api);
  }

  const showGarantie = (item, choice) => {
    const opt = choice.options.find(
      (o) => o.code === item.getChoice(choice.code)?.selected
    );
    if (opt) {
      setShowModal(opt);
    }
  };

  if (!visible) return <></>;

  const periodicity = {
    M: "mois",
    A: "an",
    S: "semestre",
    T: "trimestre",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 730,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Stack className={"FleetMatrix"} direction={"column"}>
      <h2>
        {title}
        <p>{subtitle}</p>
      </h2>

      <div
        className={"FleetMatrix__grid"}
        style={{
          gridTemplateColumns:
            "200px repeat(" +
            firstOptions.length +
            ", 1fr)" +
            (columnChoices.length > 0 ? " 200px" : ""),
        }}
      >
        <div></div>
        {firstOptions.map((option, index) => (
          <div
            key={`${option.code}-${index}`}
            className={"FleetMatrix__Header1"}
          >
            <div>{option.title}</div>
            <a className={"garanties"} onClick={() => setShowModal(option)}>
              Détail des garanties
            </a>
          </div>
        ))}
        {columnChoices.length > 0 && (
          <div className={"FleetMatrix__Header2"}>Options</div>
        )}

        {basket.getItems(asset.code).map((item, i) => (
          <React.Fragment key={`matrix-key${i}`}>
            <div className={"FleetMatrix__Item"} key={"Item-" + i}>
              {item.type && (
                <img
                  className={"FleetMatrix__ItemImage"}
                  src={
                    assetDef.types.find((t) => t.code === item.type)?.image.url
                  }
                />
              )}
              <div className={"FleetMatrix__ItemTitle"}>{item.title}</div>
              {item.periodicPremium.toFixed(2) !== 
              item.periodicPremiumDiscounted.toFixed(2) && (
                <div className={"FleetMatrix__ItemPriceDiscounted"}>
                  {item.periodicPremium.toFixed(2)}€
                  <span>/{periodicity[item.periodicity]}</span>
                </div>
              )}
              <div className={"FleetMatrix__ItemPrice"}>
                {item.periodicPremiumDiscounted.toFixed(2)}€
                <span>/{periodicity[item.periodicity]}</span>
              </div>
            </div>

            {firstOptions.map((opt, i) => (
              <div
                key={"Item-" + i + "-" + opt.code}
                className={
                  "FleetMatrix__ItemOption " +
                  (opt.code === item.getChoice(choice).selected
                    ? "selected"
                    : "")
                }
              >
                {item
                  .getChoice(choice)
                  .options.find((o) => o.code === opt.code)
                  ?.periodicPremium.toFixed(2)}
                €
              </div>
            ))}

            {columnChoices.length > 0 && (
              <div
                className={"FleetMatrix__Options"}
                key={"Item-" + i + "-Options"}
              >
                {columnChoices.map((choice, i) => (
                  <div
                    className={
                      "FleetMatrix__SecOption " +
                      (item.getChoice(choice.code)?.selected ? "selected" : "")
                    }
                    key={"Item-" + i + "-" + choice.code}
                  >
                    <div className={"title"}>
                      {choice.title}
                      {item.getChoice(choice.code)?.selected && (
                        <a onClick={() => showGarantie(item, choice)}>
                          Détail garanties
                        </a>
                      )}
                      {!readonly && <a>Modifier</a>}
                      <a>
                        {!item.getChoice(choice.code)?.selected &&
                          "A partir de " +
                            item
                              .getChoice(choice.code)
                              ?.starting("periodicPremium")
                              .toFixed(2) +
                            "€"}
                      </a>
                    </div>
                    <div className={"price"}>
                      {item.getChoice(choice.code)?.selected &&
                        item
                          .getChoice(choice.code)
                          ?.periodicPremium.toFixed(2) + "€"}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <Modal open={!!showModal} onClose={() => setShowModal(null)}>
        <Box sx={style} className={"FleetMatrix__Modal"}>
          <Stack direction="column" spacing={2}>
            {showModal && (
              <Stack
                direction="column"
                alignItems="stretch"
                spacing={3}
                className={"FleetMatrix__ModalBody"}
              >
                <h2>Détail des garanties &#8220;{showModal.title}&#8221;</h2>
                <ReactMarkdown children={showModal.description} />
              </Stack>
            )}
            <Stack direction="row" justifyContent={"center"} spacing={2}>
              <Button variant="contained" onClick={() => setShowModal(false)}>
                Fermer
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default Component;
