import { apiGetOne, apiUrl, apiPost } from "./ApiService";
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/react";

export const setToken = (token) => localStorage.setItem("token", token);
export const getToken = () => localStorage.getItem("token");

export const config = () => ({
  headers: { Authorization: `Bearer ${getToken()}` },
});

export const tokenValidate = async () => {
  const token = new URLSearchParams(window.location.search).get("jwt");
  if (token) {
    setToken(token);
  }
  if (!getToken()) return false;
  try {
    const u = await apiGetOne(`${apiUrl}/me`);
    try {
      Sentry.configureScope((scope) => {
        scope.clear();
        scope.setUser({ email: u.username });
      });
    } catch (e) {
      console.error(e);
    }
    return true;
  } catch (err) {
    return false;
  }
};
export const getUserName = () =>
  getToken() ? jwt_decode(getToken()).username : "";

export const getUserInfo = () => (getToken() ? jwt_decode(getToken()) : null);

export const logout = () => apiGetOne(`${apiUrl}/logout`);

export const login = async (data) => apiPost(`${apiUrl}/login`, data);
