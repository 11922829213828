import React, { useEffect, useRef } from "react";
import LongLabel from "../components/LongLabel/LongLabel";
import { Stack, TextField, Button, CircularProgress } from "@mui/material";
import { evalEvent, useTrackableState } from "../service/PageService";
import axios from "axios";

const Component = ({ registry, events, ...args }) => {
  const [label, setLabel] = useTrackableState(args.label || "");
  const [required, setRequired] = useTrackableState(args.required || false);
  const [error, setError] = useTrackableState(false);
  const [selectedCP, setSelectedCP] = useTrackableState(null);
  const [firstTime, setFirstTime] = useTrackableState(true);
  const [placeholder, setPlaceHolder] = useTrackableState(
    args.placeholder || ""
  );
  const [visible, setVisible] = useTrackableState(true);
  const ref = useRef();

  const [cpInputError, setCpInputError] = useTrackableState("");

  const [citiesOfSelectedCP, setcitiesOfSelectedCP] = useTrackableState([]);
  const [selectedCity, setSelectedCity] = useTrackableState(null);
  const [loadingCities, setLoadingCities] = useTrackableState(false);

  const validates = async () => {
    const state = {
      error: false,
      messages: [],
    };

    if (firstTime) {
      setFirstTime(false);
      return state.error;
    }
    if (required && !selectedCP) {
      state.error = true;
      state.messages.push("Champ '" + label + "' obligatoire.");
    }

    await evalEvent(events, "validate", {
      error: (msg) => {
        state.error = true;
        state.messages.push(msg);
      },
    });

    setError(state.error ? state.messages.join("\n") : false);

    return state;
  };

  useEffect(() => {
    validates().then(async () => evalEvent(events, "change"));
  }, [selectedCP, required]);

  const handleCPChange = async (e, input) => {
    const isValidPostalCode = /^\d{5}$/.test(input.trim());

    if (input.length < 3) {
      setcitiesOfSelectedCP([]);
      setCpInputError("");
      setLoadingCities(false);
      return;
    }

    try {
      const response = await axios.get(
        process.env.REACT_APP_GOUV_FR_API_ADDRESS_URL,
        {
          params: {
            q: input,
            type: "municipality",
          },
        }
      );

      const cities = response?.data?.features?.map((feature) => ({
        cp: feature.properties.postcode,
        ville: feature.properties.city,
      }));

      if (isValidPostalCode) {
        setCpInputError("");
        if (cities.length === 0) {
          setCpInputError("Pas de communes pour ce code postal");
        }
      } else {
        setCpInputError("");
        if (cities.length === 0) {
          setCpInputError("Aucun résultat pour l'entrée de données spécifiée");
        }
      }

      setcitiesOfSelectedCP(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setcitiesOfSelectedCP([]);
      setCpInputError("");
    } finally {
      setLoadingCities(false);
    }
  };

  const handleCityButtonClick = (city) => {
    const updatedSelectedCity = city;
    setSelectedCity(updatedSelectedCity);
  };

  if (args.code) {
    const api = {
      focus: () => {
        ref.current.focus();
      },
      validates: validates,
    };
    Object.defineProperties(api, {
      label: {
        get: () => label,
        set: (v) => setLabel(v),
      },
      visible: {
        get: () => visible,
        set: (v) => setVisible(v),
      },
      placeholder: {
        get: () => placeholder,
        set: (v) => setPlaceHolder(v),
      },
      value: {
        get: () => selectedCity,
      },
      required: {
        get: () => required,
        set: (v) => setRequired(v),
      },
      error: {
        get: () => error,
        set: (v) => setError(v),
      },
    });

    registry.set(args.code, api);
  }

  if (!visible) return <></>;

  return (
    <Stack direction={"column"} gap={1} style={{ flex: 1 }}>
      {label && <LongLabel label={label} required={required} />}
      <TextField
        inputRef={ref}
        error={!!cpInputError}
        helperText={cpInputError}
        fullWidth={true}
        placeholder={placeholder}
        onChange={(e) => handleCPChange(e, e.target.value)}
      />

      {loadingCities ? (
        <CircularProgress />
      ) : (
        <Stack direction="row" spacing={2}>
          {citiesOfSelectedCP.map((city, index) => (
            <Button
              key={`${city}_${index}`}
              variant="contained"
              color={"primary"}
              onClick={() => handleCityButtonClick(city)}
            >
              {city.ville}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default Component;
