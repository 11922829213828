import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./FormulaCardField.scss";

const FormulaCardField = ({
  disabled,
  selected,
  children,
  onChange,
  onClick,
  className,
  recommended,
  ...args
}) => {
  const switchState = () => {
    onClick && onClick();
    if (disabled) return;
    onChange && onChange(!selected);
  };

  return (
    <Stack direction={"column"}>
      {recommended && (
        <div style={{ marginBottom: -40 }} className={"recommended-box"}>
          Notre recommendation
        </div>
      )}
      <div
        style={{ marginTop: 45 }}
        className={
          "FormulaCardField " +
          (selected ? "selected" : disabled ? "disabled" : "") +
          " " +
          (className || "")
        }
        onClick={switchState}
        {...args}
      >
        {children}
      </div>
    </Stack>
  );
};

export default FormulaCardField;
