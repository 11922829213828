import React, { useContext, useEffect, useState } from "react";
import { SubscriptionContext } from "./SubscriptionIndex";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  FormControlLabel,
  Button,
  Typography,
  MenuItem,
  Alert,
} from "@mui/material";
import DocumentBlock from "../DocumentBlock/DocumentBlock";
import {
  getDocumentSpecification,
  saveSubscription,
} from "../../../../core/services/BatchSubscriptionService";
import BanAddressField from "../../../../core/blocks/BanAddressField/BanAddressField";
import { Form } from "react-final-form";
import {
  Autocomplete,
  TextField,
  makeValidate,
  Switches,
  Checkboxes,
} from "mui-rff";
import { DatePicker } from "../../../../core/blocks/FormField/DatePicker";
import * as Yup from "yup";
import { KeyboardArrowLeft } from "@mui/icons-material";
import moment from "moment";
import stringSimilarity from "string-similarity";
import streetTypes from "../../../../assets/streetTypes";
import { Box } from "@mui/system";
import countries from "../../../../assets/countries";
import { checkRib } from "../../../../core/utils/checkRib";
import { bundleRefTaken, uploadMandateRiaDoc } from "../../../../core/services";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

const ALPRO_REGEXP =
  process.env.REACT_APP_ENVIRONMENT &&
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? /^ALPRO[1-9]\d{5}$/
    : /^ALPRO\d{6}$/;

Yup.setLocale({
  mixed: {
    default: "Invalide",
    required: "Obligatoire",
    typeError: "Invalide",
  },
});

const assureurData = [
  "AGPM (Association Generale de Prevoyance Militaire)",
  "GMF",
  "SARPGN",
  "Autre",
];

const LOV = {
  country: countries,
  streetType: streetTypes,
};

const textFieldOptions = {
  variant: "outlined",
  size: "small",
};

const fieldsValidate = makeValidate(
  Yup.object().shape({
    // Date
    signingDate: Yup.date()
      .required()
      .nullable(),
      // .typeError("Date invalide")
      // .test("", (value) =>
      //   moment(value).isSameOrAfter(moment().subtract(3, "months"), "D")
      // ),
    effectiveFrom: Yup.date()
      .required()
      .nullable(),
      // .max(moment().add(1, "y"), "Date invalide")
      // .typeError("Date invalide"),
    periodicity: Yup.string()
      .required("Obligatoire")
      .oneOf(["A", "M"], "Valeur incorrect"),

    // Adresse :
    building: Yup.string().notRequired(),
    additional: Yup.string().test("test-additional", (v, o) =>
      !!v || (!!o.parent.street && !!o.parent.streetType)
        ? true
        : o.createError({
            message: "Complément d'adresse ou\nType voie + Voie",
          })
    ),
    street: Yup.string().test("test-street", (v, o) =>
      !!v || !!o.parent.additional
        ? true
        : o.createError({ message: "Obligatoire" })
    ),
    streetNumber: Yup.string().notRequired(),
    streetType: Yup.string()
      .test("test-streetType", (v, o) =>
        !!v || !!o.parent.additional
          ? true
          : o.createError({ message: "Obligatoire" })
      )
      .nullable()
      .oneOf([streetTypes.map((streetType) => streetType.label)], (e) =>
        e.value !== null && e.value !== "" ? "Valeur incorrecte" : true
      ),
    zipCode: Yup.string()
      .matches(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/, "CP incorrect.")
      .required(),
    city: Yup.string().required(),
    country: Yup.string()
      .required()
      .nullable()
      .oneOf(
        countries.map((country) => country.label),
        (e) => (e.value !== null && e.value !== "" ? "Valeur incorrecte" : true)
      ),

    // Risque
    bundleRef: Yup.string()
      .required("Obligatoire")
      .matches(ALPRO_REGEXP, "Format ALPRO123456")
      .test(
        "bundleref-backend-validation",
        "Numéro de liasse déjà utilisé",
        async (bundleRef) => {
          const regExp =
            /^.*\/batch-subscriptions\/([a-fA-F0-9]{24})\/subscriptions\/([a-fA-F0-9]{24})\/fill\/contract$/;
          const match = window.location.href.match(regExp);
          return match && match[2] && bundleRef && ALPRO_REGEXP.test(bundleRef)
            ? !(await bundleRefTaken(match[2], bundleRef))
            : true;
        }
      ),

    dematConsent: Yup.boolean(),

    // RIA
    isRia: Yup.boolean(),
    prevInsurer: Yup.string().when("isRia", {
      is: true,
      then: Yup.string().required().oneOf(assureurData),
      otherwise: Yup.string().notRequired(),
    }),
    prevInsurerOther: Yup.string().when("prevInsurer", {
      is: "Autre",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    prevRiskLocation: Yup.string().when("isRia", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    prevContractRef: Yup.string().when("isRia", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    riaMandateFile: Yup.string().when("isRia", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),

    // RIB
    ribBanque: Yup.string()
      .required("Obligatoire ")
      .matches(/^[0-9]{5}$/, "5 chiffres"),
    ribGuichet: Yup.string()
      .required("Obligatoire ")
      .matches(/^[0-9]{5}$/, "5 chiffres"),
    ribCompte: Yup.string()
      .required("Obligatoire ")
      .matches(/^[A-Za-z0-9]{11}$/, "11 caractères"),
    ribCle: Yup.string()
      .required("Obligatoire ")
      .matches(/^[0-9]{2}$/, "2 chiffres"),
    ribDomiciliation: Yup.string()
      .test(
        "is-domiciliation",
        "Veuillez saisir une domiciliation valide",
        (v) => (v ? !v.match(/\d{6,40}/) : true)
      )
      .required("Obligatoire ")
      .max(40, "Moins de 40 caractères"),
    ribBic: Yup.string()
      .required("Obligatoire ")
      .matches(
        /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
        "ex:PSSSFR22XXX,AGRIFRPP883"
      ),
    ribOwner: Yup.string()
      .required("Obligatoire ")
      .min(4, "Au moins 4 caractères"),
  })
);

const SubscriptionFormContract = () => {
  let navigate = useNavigate();
  let [documents, setDocuments] = useState([]);
  let { batchId, subscriptionId } = useParams();
  let [initialValues, setInitialValues] = useState({});
  let [currentDocument, setCurrentDocument] = useState("id");
  const { subscription, batch } = useContext(SubscriptionContext);
  const [prevInsurerValue, setPrevInsurerValue] = useState("");
  const [mandateRiaFile, setMandateRiaFile] = useState("");

  const validate = async (values) => {
    const errors = await fieldsValidate(values);
    if (
      values.effectiveFrom &&
      values.signingDate &&
      values.effectiveFrom <= values.signingDate
    ) {
      errors.effectiveFrom = "Incorrect";
    }
    if (values.signingDate && !values.effectiveFrom) {
      setInitialValues({
        ...initialValues,
        effectiveFrom: new Date(
          new Date(values.signingDate).setDate(
            new Date(values.signingDate).getDate() + 1
          )
        ),
        signingDate: values.signingDate,
      });
    }
    // Handle RIB validation
    if (
      values.ribBanque &&
      values.ribGuichet &&
      values.ribCompte &&
      values.ribCle
    ) {
      const ribCheck = checkRib(
        values.ribBanque,
        values.ribGuichet,
        values.ribCompte,
        values.ribCle
      );
      if (!ribCheck.isValid) {
        errors.ribBanque = "Incorrect";
        errors.ribGuichet = "Incorrect";
        errors.ribCompte = "Rib incorrect";
        errors.ribCle = "Incorrect";
      }
    }

    return errors;
  };

  const onSubmit = async (values) => {
    try {
      subscription.signingDate = values.signingDate.format("YYYY-MM-DD");
      subscription.effectiveFrom = values.effectiveFrom.format("YYYY-MM-DD");
      subscription.dematConsent = values.dematConsent;
      subscription.bundleRef = values.bundleRef;
      subscription.isRia = values.isRia;
      if (values.prevInsurer === "Autre") {
        subscription.prevInsurer = `Autre : ${values.prevInsurerOther}`;
      } else {
        subscription.prevInsurer = values.prevInsurer;
      }
      subscription.prevContractRef = values.prevContractRef;
      subscription.prevRiskLocation = values.prevRiskLocation;
      subscription.ribBanque = values.ribBanque;
      subscription.ribGuichet = values.ribGuichet;
      subscription.ribCompte = values.ribCompte;
      subscription.ribCle = values.ribCle;
      subscription.ribDomiciliation = values.ribDomiciliation;
      subscription.ribBic = values.ribBic;
      subscription.ribOwner = values.ribOwner;
      subscription.ribIban = checkRib(
        values.ribBanque,
        values.ribGuichet,
        values.ribCompte,
        values.ribCle
      ).iban;
      subscription.status = "SYNC";
      subscription.policyHolder.riskAddress.building = values.building || "";
      subscription.policyHolder.riskAddress.additional =
        values.additional || "";
      subscription.policyHolder.riskAddress.streetNumber =
        values.streetNumber || "";
      subscription.policyHolder.riskAddress.street = values.street || "";
      subscription.policyHolder.riskAddress.city = values.city || "";
      subscription.policyHolder.riskAddress.zipCode = values.zipCode || "";
      subscription.policyHolder.riskAddress.streetType = LOV.streetType.find(
        (o) => o.label === values["streetType"]
      )?.id;
      subscription.policyHolder.riskAddress.country = LOV.country.find(
        (o) => o.label === values["country"]
      )?.id;
      subscription.periodicity = values.periodicity;

      await saveSubscription(subscription);
    } catch (e) {
      console.error(e);
    } finally {
      navigate(`/batch-subscriptions/batches/${batchId}`);
    }
  };

  const goBack = () => {
    navigate(
      `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/fill/person`
    );
  };

  useEffect(() => {
    if (!subscription) return;
    const specs = getDocumentSpecification();
    setDocuments(
      subscription.supportingDocuments.map((d) => {
        return {
          value: d.documentType,
          label:
            specs.find((s) => s.type === d.documentType)?.label || "Inconnu",
          _id: d._id,
        };
      })
    );

    if (!subscription.signingDate) {
      subscription.signingDate = batch.sessionDate;
      subscription.effectiveFrom = new Date(
        new Date(subscription.signingDate).setDate(
          new Date(subscription.signingDate).getDate() + 1
        )
      );
    }

    if (subscription.mandateRia.externalRef) {
      setMandateRiaFile(subscription.mandateRia.originalName);
    }

    const init = {
      signingDate: subscription.signingDate
        ? moment(subscription.signingDate)
        : null,
      effectiveFrom: subscription.effectiveFrom
        ? moment(subscription.effectiveFrom)
        : null,
      periodicity: subscription.periodicity || "",
      dematConsent: subscription.dematConsent || false,
      bundleRef: subscription.bundleRef || "",
      isRia: subscription.isRia || false,
      prevInsurer: subscription.prevInsurer
        ? assureurData.find((i) => i === subscription.prevInsurer) || "Autre"
        : "",
      prevInsurerOther: subscription.prevInsurer || "",
      prevContractRef: subscription.prevContractRef || "",
      prevRiskLocation: subscription.prevRiskLocation || "",
      city: batch.riskAddress.city,
      building: batch.riskAddress.building,
      additional: batch.riskAddress.additional,
      streetNumber: batch.riskAddress.streetNumber,
      street: batch.riskAddress.street,
      country:
        LOV.country.find((elt) => elt.id === batch.riskAddress.country)
          ?.label || "",
      streetType:
        LOV.streetType.find((elt) => elt.id === batch.riskAddress.streetType)
          ?.label || "",
      zipCode: batch.riskAddress.zipCode,
      ribBanque: subscription.ribBanque || "",
      ribGuichet: subscription.ribGuichet || "",
      ribCompte: subscription.ribCompte || "",
      ribCle: subscription.ribCle || "",
      ribDomiciliation: subscription.ribDomiciliation || "",
      ribBic: subscription.ribBic || "",
      ribOwner: subscription.ribOwner || "",
      riaMandateFile: "",
    };
    setInitialValues(init);
    setPrevInsurerValue(init.prevInsurer);
  }, [subscription]);

  const setAddressFields = (args, state) => {
    // streetType
    const res = stringSimilarity.findBestMatch(
      args[0].street.split(" ").shift() || "Rue",
      streetTypes.map((elt) => elt.label)
    );
    let streetType = "Rue";
    if (res.bestMatch.rating > 0) {
      streetType = streetTypes[res.bestMatchIndex].label;
    }
    // street
    const index = streetTypes.indexOf(
      streetTypes.find((i) => i.label === streetType)
    );
    let street = args[0].street
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/-+/g, " "), "gi"),
        ""
      )
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/ +/g, "-"), "gi"),
        ""
      )
      ?.trim();
    state.fields["streetNumber"].change(args[0].housenumber);
    state.fields["streetType"].change(streetType);
    state.fields["street"].change(street);
    state.fields["zipCode"].change(args[0].postcode);
    state.fields["city"].change(args[0].city);
    state.fields["country"].change("FRA");
  };

  const handleMandateRiaUplaod = async (e) => {
    const files = e.target.files;
    if (files && files.length === 1) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file, file.name);
      await uploadMandateRiaDoc(subscriptionId, formData);
      setMandateRiaFile(file.name);
    }
  };
  return (
    <Form
      mutators={{ setAddressFields }}
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, values, errors }) => (
        <>
          {/* <pre>{JSON.stringify(errors, null, 1)}</pre> */}
          <form onSubmit={handleSubmit} noValidate>
            <div className="Subscription">
              <div style={{ width: "50%" }}>
                <Typography variant="h2">Contrat 'Protect'</Typography>
                <Typography variant="p">
                  Saisie des informations contrats
                </Typography>

                <Stack sx={{ marginTop: "30px" }} spacing={2}>
                  <Typography variant="h4">
                    Information Police Protect
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Box onFocus={() => setCurrentDocument("da")}>
                      <DatePicker
                        name="signingDate"
                        label="Date de signature"
                        inputFormat="DD/MM/YYYY"
                        required={true}
                        textFieldProps={{ ...textFieldOptions }}
                        placeholder="jj/mm/aaaa"
                      />
                    </Box>
                    <Box onFocus={() => setCurrentDocument("da")}>
                      <DatePicker
                        name="effectiveFrom"
                        label="Date d'effet"
                        inputFormat="DD/MM/YYYY"
                        required={true}
                        textFieldProps={{ ...textFieldOptions }}
                        onFocus={() => setCurrentDocument("da")}
                        placeholder="jj/mm/aaaa"
                      />
                    </Box>
                    <TextField
                      {...textFieldOptions}
                      select
                      name="periodicity"
                      label="Fractionnement"
                      required={true}
                      sx={{ width: 300 }}
                      onFocus={() => setCurrentDocument("da")}
                      disabled={subscription.isStudent}
                    >
                      <MenuItem value="A">Annuel</MenuItem>
                      <MenuItem value="M">Mensuel</MenuItem>
                    </TextField>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <BanAddressField
                      name="banAddress"
                      required={true}
                      label={"Adresse du risque"}
                      {...textFieldOptions}
                      fullWidth={true}
                      updateAddress={form.mutators.setAddressFields}
                      onFocus={() => setCurrentDocument("da")}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      label="Bâtiment / Chambre"
                      name="building"
                      required={false}
                      placeholder="Bâtiment / Chambre"
                      title="Bâtiment / Chambre"
                      sx={{ width: 190 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                    <TextField
                      {...textFieldOptions}
                      label="Complément d'adresse"
                      name="additional"
                      placeholder="Complément d'adresse"
                      title="Complément d'adresse"
                      sx={{ width: 230 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      label="N°"
                      name="streetNumber"
                      required={false}
                      placeholder="N°"
                      title="N°"
                      sx={{ width: 90 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                    <Autocomplete
                      size="small"
                      disablePortal
                      name="streetType"
                      required={true}
                      label="Type voie"
                      options={["", ...LOV.streetType.map((a) => a.label)]}
                      sx={{ width: 200 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                    <TextField
                      {...textFieldOptions}
                      label="Voie"
                      name="street"
                      required={true}
                      placeholder="Voie"
                      title="Voie"
                      fullWidth={true}
                      onFocus={() => setCurrentDocument("da")}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      label="Code Postal"
                      name="zipCode"
                      required={true}
                      placeholder="Code Postal"
                      title="Code Postal"
                      sx={{ width: 130 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                    <TextField
                      {...textFieldOptions}
                      label="Ville"
                      required={true}
                      placeholder="Ville"
                      title="Ville"
                      name="city"
                      sx={{ width: 200 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                    <Autocomplete
                      size="small"
                      name={"country"}
                      disablePortal
                      label="Pays"
                      required={true}
                      options={["", ...LOV.country.map((a) => a.label)]}
                      sx={{ width: 190 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      name="bundleRef"
                      label="Num de liasse"
                      placeholder="Num de liasse"
                      required={true}
                      title="Num de liasse"
                      sx={{ width: 200 }}
                      onFocus={() => setCurrentDocument("da")}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel
                      control={
                        <Checkboxes
                          data={{
                            label:
                              "Consentement à recevoir les offres Allianz par courrier électronique.",
                            value: false,
                          }}
                          name="dematConsent"
                          color="primary"
                          onFocus={() => setCurrentDocument("da")}
                        />
                      }
                      {...textFieldOptions}
                    />
                  </Stack>
                  <h2>
                    <Switches
                      name="isRia"
                      data={{
                        label: "Résiliation Infra Annuelle",
                        value: false,
                      }}
                    />
                  </h2>
                  {values.isRia && (
                    <>
                      <Alert severity="warning">
                        Une RIA n'est possible que pour les contrats ayant plus
                        d'un an.
                      </Alert>
                      <Autocomplete
                        label="Assureur précedent"
                        disablePortal
                        required={true}
                        options={assureurData}
                        {...textFieldOptions}
                        // sx={{ width: 200 }}
                        name="prevInsurer"
                        onFocus={() => setCurrentDocument("da")}
                        onChange={(e, value) => setPrevInsurerValue(value)}
                      />
                      <Stack direction="row" spacing={2}>
                        <TextField
                          {...textFieldOptions}
                          name="prevRiskLocation"
                          required={true}
                          label="Localisation du risque précédent"
                          placeholder="Localisation du risque précédent"
                          title="Localisation du risque précédent"
                          fullWidth={true}
                          onFocus={() => setCurrentDocument("da")}
                        />
                        <TextField
                          {...textFieldOptions}
                          name="prevContractRef"
                          required={true}
                          label="Num contrat précédent"
                          placeholder="Num contrat précédent"
                          title="Num contrat précédent"
                          fullWidth={true}
                          onFocus={() => setCurrentDocument("da")}
                        />
                      </Stack>
                      {prevInsurerValue === "Autre" && (
                        <TextField
                          {...textFieldOptions}
                          label="Nom assureur précedent"
                          name="prevInsurerOther"
                          disablePortal
                          required={true}
                          sx={{ width: 200 }}
                          onFocus={() => setCurrentDocument("da")}
                        />
                      )}
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Button
                          variant="outlined"
                          size="small"
                          component="label"
                          startIcon={<UploadFileOutlinedIcon />}
                        >
                          Mandat RIA
                          <input
                            type="file"
                            style={{ display: "none" }}
                            accept=".pdf,.jpg,.jpeg,.png"
                            onChange={(e) => {
                              handleMandateRiaUplaod(e);
                              form.change("riaMandateFile", "done");
                            }}
                          />
                        </Button>
                        {!(errors && errors["riaMandateFile"]) && (
                          <Typography variant="subtitle2">
                            Fichier mandat RIA : {mandateRiaFile}
                          </Typography>
                        )}
                        {errors && errors["riaMandateFile"] && (
                          <Alert severity="error">
                            Veuillez fournir le mandat RIA correspond
                          </Alert>
                        )}
                      </Stack>
                    </>
                  )}
                  <Typography variant="h4">RIB</Typography>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      name="ribBanque"
                      label="Banque"
                      sx={{ width: 190 }}
                      required={true}
                      placeholder="Banque"
                      title="Banque"
                      inputProps={{ maxLength: 5 }}
                      onFocus={() => setCurrentDocument("rib")}
                    />
                    <TextField
                      {...textFieldOptions}
                      name="ribGuichet"
                      label="Guichet"
                      sx={{ width: 190 }}
                      required={true}
                      placeholder="Guichet"
                      title="Guichet"
                      inputProps={{ maxLength: 5 }}
                      onFocus={() => setCurrentDocument("rib")}
                    />
                    <TextField
                      {...textFieldOptions}
                      name="ribCompte"
                      label="Compte"
                      fullWidth={true}
                      required={true}
                      placeholder="Compte"
                      title="Compte"
                      inputProps={{ maxLength: 11 }}
                      onFocus={() => setCurrentDocument("rib")}
                    />
                    <TextField
                      {...textFieldOptions}
                      name="ribCle"
                      label="Clef"
                      sx={{ width: 150 }}
                      required={true}
                      placeholder="Clef"
                      title="Clef"
                      onFocus={() => setCurrentDocument("rib")}
                      inputProps={{ maxLength: 2 }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      name="ribDomiciliation"
                      label="Domiciliation"
                      sx={{ width: 380 }}
                      required={true}
                      placeholder="Domiciliation"
                      title="Domiciliation"
                      onFocus={() => setCurrentDocument("rib")}
                    />
                    <TextField
                      {...textFieldOptions}
                      name="ribBic"
                      label="Bic"
                      sx={{ width: 200 }}
                      required={true}
                      placeholder="Bic"
                      title="Bic"
                      onFocus={() => setCurrentDocument("rib")}
                    />
                  </Stack>
                  <TextField
                    {...textFieldOptions}
                    name="ribOwner"
                    label="Titulaire du RIB"
                    sx={{ width: 260 }}
                    required={true}
                    placeholder="Titulaire du RIB"
                    title="Titulaire du RIB"
                    onFocus={() => setCurrentDocument("rib")}
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  sx={{ marginTop: "10px" }}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={goBack}
                  >
                    Précédent
                  </Button>
                  <Button color="info" variant="contained" type="submit">
                    Valider
                  </Button>
                </Stack>
              </div>

              <div className="Subscription__documentBlock-container">
                <div className="Subscription__btPrecedent">
                  <Link to={`/batch-subscriptions/batches/${batchId}`}>
                    <Button
                      variant="outlined"
                      disabled
                      startIcon={<KeyboardArrowLeft />}
                    >
                      Retour
                    </Button>
                  </Link>
                </div>
                <DocumentBlock
                  documents={documents}
                  currentDocument={currentDocument}
                  setCurrentDocument={setCurrentDocument}
                  subscriptionId={subscriptionId}
                />
              </div>
            </div>
          </form>
        </>
      )}
    />
  );
};

export default SubscriptionFormContract;
