const initChoice = (choices, choiceSpec, basket) => {
  if (choiceSpec.code && !choices.has(choiceSpec.code)) {
    if (choiceSpec.options.length === 0) return;
    choices.set(
      choiceSpec.code,
      BasketChoice(
        {
          selected: false,
          code: choiceSpec.code,
          periodicity: choiceSpec.periodicity,
          options: choiceSpec.options.map((o) => ({
            code: o.code,
            premium: o.premium || 0,
            periodicPremium: o.periodicPremium || 0,
          })),
        },
        basket
      )
    );
  }
};

const periodicityMap = {
  M: 12,
  S: 2,
  A: 1,
  T: 4,
};
export const periodicityLabel = {
  M: "mois",
  S: "semestre",
  A: "an",
  T: "trimestre",
};

const applyNavigable = (o, _history) => {
  o.currentPage = () => {
    if (_history.length === 0 || _history[0] === "$") return -1;
    return parseInt(_history[_history.length - 1]);
  };
  o.goTo = (formId) => {
    _history.push(formId + "");
  };
  o.goBack = () => {
    if (_history.length === 0) return -1;
    if (_history.length === 1 && _history[0] === "$") return -1;
    const removedPage = parseInt(_history.pop());
    return removedPage;
  };
  o.goEnd = () => {
    _history.splice(0, _history.length, "$");
  };
  o.goBegin = () => {
    _history.splice(0, _history.length);
  };
  o.isComplete = () => {
    return _history.length > 0 && _history[0] === "$";
  };
};

export const Basket = (_data) => {
  const o = {};
  let status = "DRAFT";
  let subscriberId = null;
  let quoteId = null;
  let properties = {};
  const choices = new Map();
  const items = new Map();
  let externalRef = null;

  let periodicity = "M";
  let premium = 0;
  let periodicPremium = 0;

  let periodicPremiumDiscounted = 0;
  let premiumDiscounted = 0;

  let product = null;
  let id = null;
  const history = [];
  let callback = () => {};

  const updatePrice = () => {
    premium = 0;
    periodicPremium = 0;

    let periodicities = new Set();
    for (const choice of choices.values()) {
      if (choice.selected) {
        periodicities.add(choice.periodicity);
        premium += choice.premium;
        periodicPremium +=
          periodicityMap[choice.periodicity] * choice.periodicPremium;
      }
    }
    for (const itemList of items.values()) {
      for (const item of itemList) {
        periodicities.add(item.periodicity);
        premium += item.premium;
        periodicPremium +=
          periodicityMap[item.periodicity] * item.periodicPremium;
      }
    }
    const p = periodicities.size > 1 ? [...periodicities.values()][0] : "M";
    periodicPremium = periodicPremium / periodicityMap[p];
    periodicity = p;
  };

  o.updatePrice = updatePrice;

  o.isEmpty = () => {
    return false;
  };

  o.toJson = () => {
    return {
      status: status,
      properties: properties,
      subscriberId: subscriberId,
      product: product,
      externalRef: externalRef,
      quoteId: quoteId,
      periodicPremiumDiscounted: periodicPremiumDiscounted,
      periodicPremium: periodicPremium,
      premiumDiscounted: premiumDiscounted,
      premium: premium,
      history,
      choices: Array.from(choices.values()).reduce((previousValue, choice) => {
        previousValue[choice.code] = choice.toJson();
        return previousValue;
      }, {}),
      items: Array.from(items.keys()).reduce((previousValue, col) => {
        previousValue[col] = items.get(col).map((i) => i.toJson());
        return previousValue;
      }, {}),
    };
  };

  const fromJson = function (data) {
    status = data.status || "DRAFT";
    subscriberId = data.subscriberId || null;
    quoteId = data.quoteId || null;
    externalRef = data.externalRef || null;
    properties = data.properties || {};
    premiumDiscounted = data.premiumDiscounted || 0;
    periodicPremiumDiscounted = data.periodicPremiumDiscounted || 0;
    product = data.product || null;
    Object.keys(data.choices || {}).forEach((choiceKey) => {
      choices.set(
        choiceKey,
        BasketChoice({ ...data.choices[choiceKey], code: choiceKey }, o)
      );
    });
    Object.keys(data.items || {}).forEach((type) => {
      items.set(
        type,
        data.items[type].map((item) => BasketItem(item, o))
      );
    });
    id = data._id || id;
    history.splice(
      0,
      history.length,
      ...(data.history?.map((h) => h + "") || [])
    );
    updatePrice();
  };
  o.fromJson = fromJson;
  fromJson(_data || {});

  o.get = (prop) => {
    if (!properties.hasOwnProperty(prop)) {
      return undefined;
    }
    return properties[prop];
  };

  o.set = (prop, value) => {
    properties[prop] = value;
    callback && callback();
    return o;
  };

  o.has = (prop) => {
    return properties.hasOwnProperty(prop);
  };

  o.getChoice = (key) => {
    return choices.get(key);
  };

  o.getChoices = () => {
    return Array.from(choices.values());
  };

  o.initChoice = (choiceSpec) => {
    initChoice(choices, choiceSpec, o);
  };

  o.getItems = (type) => {
    return items.get(type) || [];
  };

  o.getItem = (type, i) => {
    if (!items.has(type)) return null;
    return items.get(type)[i];
  };

  o.addItem = (type, _data) => {
    if (!items.has(type)) items.set(type, []);
    const item = BasketItem(_data, o);
    items.get(type).push(item);
    callback && callback();
    return item;
  };

  o.removeItem = (type, i) => {
    if (!items.has(type)) return null;
    const _items = items.get(type);
    if (i < _items.length) {
      _items.splice(i, 1);
    }
  };

  o.setCallback = (c) => (callback = c);
  o.callback = () => callback();

  applyNavigable(o, history);

  Object.defineProperties(o, {
    quoteId: {
      get: () => quoteId,
      set: (q) => (quoteId = q),
    },
    externalRef: {
      get: () => externalRef,
    },
    premium: {
      get: () => premium,
    },
    periodicPremium: {
      get: () => periodicPremium,
    },
    premiumDiscounted: {
      get: () => (premiumDiscounted ? premiumDiscounted : premium),
    },
    periodicPremiumDiscounted: {
      get: () =>
        periodicPremiumDiscounted ? periodicPremiumDiscounted : periodicPremium,
    },
    discount: {
      get: () => premiumDiscounted - premium,
    },
    periodicDiscount: {
      get: () => periodicPremiumDiscounted - periodicPremium,
    },
    periodicity: {
      get: () => periodicity,
    },
    product: {
      get: () => product,
    },
    history: {
      get: () => history,
    },
    id: {
      get: () => id,
    },
  });

  return o;
};

const BasketItem = (_data, basket) => {
  let properties = {};
  const choices = new Map();

  let periodicity = "M";
  let premium = 0;
  let periodicPremium = 0;

  let premiumDiscounted = 0;
  let periodicPremiumDiscounted = 0;

  let title = "";
  let type = null;
  const history = [];
  let hasChanged = false;

  const o = {};

  o.trackChanges = () => (hasChanged = false);

  const updatePrice = () => {
    premium = 0;
    periodicPremium = 0;

    let periodicities = new Set();
    for (const choice of choices.values()) {
      if (choice.selected) {
        periodicities.add(choice.periodicity);
        premium += choice.premium;
        periodicPremium +=
          periodicityMap[choice.periodicity] * choice.periodicPremium;
      }
    }
    const p = periodicities.size > 1 ? [...periodicities.values()][0] : "M";
    periodicPremium = periodicPremium / periodicityMap[p];
    periodicity = p;
    basket.updatePrice();
  };

  o.updatePrice = updatePrice;

  o.toJson = () => {
    return {
      type: type,
      properties: properties,
      choices: Array.from(choices.values()).reduce((previousValue, choice) => {
        previousValue[choice.code] = choice.toJson();
        return previousValue;
      }, {}),
      premiumDiscounted: premiumDiscounted,
      periodicPremiumDiscounted: periodicPremiumDiscounted,
      premium: premium,
      periodicPremium: periodicPremium,
      history,
      title,
    };
  };

  const fromJson = function (data) {
    Object.keys(data.choices || {}).forEach((choiceKey) => {
      if (choices.has(choiceKey)) {
        choices.get(choiceKey).fromJson(data.choices[choiceKey]);
      } else {
        choices.set(
          choiceKey,
          BasketChoice({ ...data.choices[choiceKey], code: choiceKey }, basket)
        );
      }
    });
    properties = data.properties || {};
    type = data.type;
    title = data.title || "";
    premiumDiscounted = data.premiumDiscounted || 0;
    periodicPremiumDiscounted = data.periodicPremiumDiscounted || 0;
    history.splice(
      0,
      history.length,
      ...(data.history?.map((h) => h + "") || [])
    );
    updatePrice();
  };
  o.fromJson = fromJson;
  fromJson(_data || {});

  o.set = (prop, value) => {
    properties[prop] = value;
    basket.callback && basket.callback();
    return o;
  };

  o.get = (prop) => {
    if (!properties.hasOwnProperty(prop)) {
      return undefined;
    }
    return properties[prop];
  };

  o.has = (prop) => {
    return properties.hasOwnProperty(prop);
  };

  o.getChoice = (key) => {
    return choices.get(key);
  };

  o.getChoices = () => {
    return Array.from(choices.values());
  };

  o.initChoice = (choiceSpec) => {
    initChoice(choices, choiceSpec, basket);
  };

  applyNavigable(o, history);

  Object.defineProperties(o, {
    premium: {
      get: () => premium,
    },
    periodicPremium: {
      get: () => periodicPremium,
    },
    discount: {
      get: () => premiumDiscounted - premium,
    },
    premiumDiscounted: {
      get: () => premiumDiscounted,
    },
    periodicPremiumDiscounted: {
      get: () => periodicPremiumDiscounted,
    },
    periodicDiscount: {
      get: () => periodicPremiumDiscounted - periodicPremium,
    },
    periodicity: {
      get: () => periodicity,
    },
    history: {
      get: () => history,
    },
    title: {
      get: () => title,
      set: (v) => {
        title = v;
        basket.callback && basket.callback();
      },
    },
    changed: {
      get: () => hasChanged,
      set: (v) => (hasChanged = v),
    },
    type: {
      get: () => type,
      set: (a) => {
        type = a;
        basket.callback && basket.callback();
      },
    },
  });

  return o;
};
const BasketChoice = (_data, basket) => {
  let code = "";
  let premium = 0;
  let periodicPremium = 0;

  let periodicity = "M";
  let selected = false;
  let options = [];
  const o = {};

  o.select = (key) => {
    if (!key) key = false;
    if (selected !== key) {
      if (!key) {
        premium = 0;
        periodicPremium = 0;
        selected = false;
      } else {
        const opt = options.find((o) => o.code === key);
        if (!opt) return o;
        let per = periodicityMap[periodicity];
        selected = opt.code;
        premium = opt.premium;
        periodicPremium = opt.periodicPremium;
      }
      selected = key;
      basket.updatePrice();
      basket.callback && basket.callback();
    }
    return o;
  };

  o.unselect = () => {
    if (selected) {
      selected = false;
      premium = 0;
      periodicPremium = 0;
      basket.updatePrice();
      basket.callback && basket.callback();
    }
    return o;
  };
  o.isSelected = (key) => {
    return selected.indexOf(key) >= -1;
  };
  o.getSelected = () => {
    return Array.from(selected);
  };

  const fromJson = (data) => {
    periodicity = data.periodicity || "M";
    selected = false;
    options = (data.options || []).map((o) => ({
      ...o,
    }));
    code = data.code || "";
    o.select(data.selected);
    return o;
  };

  o.fromJson = fromJson;

  fromJson(_data || {});

  o.toJson = () => {
    return {
      premium,
      periodicPremium,
      periodicity,
      selected,
      code,
      options,
    };
  };

  o.starting = (prop) => {
    return Math.min(...options.map((opt) => opt[prop]));
  };

  Object.defineProperties(o, {
    isBoolean: {
      get: () => options.length === 1,
    },
    code: {
      get: () => code,
    },
    options: {
      get: () => options,
    },
    periodicPremium: {
      get: () => periodicPremium,
    },
    premium: {
      get: () => premium,
    },
    periodicity: {
      get: () => periodicity,
      set: (v) => {
        periodicity = v;
        basket.callback && basket.callback();
        return v;
      },
    },
    selected: {
      get: () => selected,
      set: (v) => {
        o.select(v);
        return v;
      },
    },
  });

  return o;
};
