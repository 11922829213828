import {apiPublicGetMany, apiPublicGetOne} from "../../../core/services";


export const getProductConfig = async (productType) => {
    const row = await apiPublicGetMany('/products?code='+productType);

    // Fix LSEP / PSEP mac special chars

    if(row.data.length === 0) {
        throw "No product "+productType;
    }


    return sanitize(row.data[0].config);
}

export const getProductById = async (id) => {
    return await apiPublicGetOne('/products/'+id);
}

const sanitize = (obj) => {

    // Remove special chars
    const clean = (v) => v.replaceAll(/[\u2028\u2029\u0085]/g,'\n');

    if(Array.isArray(obj)) {
        obj.forEach((o,k) => {
            if(o === null) return;
            if(typeof o === "string") obj[k] = clean(o);
            else if(typeof o === "object") sanitize(o);
        });
    }

    Object.keys( obj).forEach( k => {
        if(obj[k] === null) return;
        if(typeof obj[k] === "string") obj[k] = clean(obj[k]);
        else if(typeof obj[k] === "object") sanitize(obj[k]);
    })

    return obj;
}