import axios from "axios";
/**
 * Set Axios instance
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

api.defaults.headers.common["Content-Type"] = "application/json";
const tokenAccess = localStorage.getItem("token");
if (tokenAccess) {
  api.defaults.headers.common["Authorization"] = `Bearer ${tokenAccess}`;
}

export const getStepsFn = async (inputs) => {
  const [_key, { quoteId }] = inputs.queryKey;
  const resp = await api.get(`/quotes/${quoteId}/steps`);
  return resp.data;
};

export const remoteSigningFn = async ({ quoteId, code }) => {
  const response = await api.post(`/quotes/${quoteId}/remote-signing`, {
    stepCode: code,
  });
  return response.data;
};

export const sendPartsFn = async ({ quoteId, code }) => {
  const response = await api.post(`/quotes/${quoteId}/send-parts`, {
    stepCode: code,
  });
  return response.data;
};

export const CheckDocapostCircuitFn = async (inputs) => {
  const [_key, { quoteId, code }] = inputs.queryKey;
  const resp = await api.get(`/quotes/${quoteId}/docapost/${code}`);
  return resp.data;
};

/**
 * Process the current step
 * @param {*} inputs Inputs
 * @returns {Promise<*>} Res
 */
export const processStepsFn = async (inputs) => {
  const { stepCode, quoteId, files, formFields } = inputs;
  const formData = new FormData();
  if (files) {
    for (let index = 0; index < files.length; index++) {
      formData.append("documents", files[index], files[index].name);
    }
  }
  if (formFields) {
    Object.entries(formFields).forEach(([key, value]) => {
      formData.append(key, value);
    });
  }
  const response = await api.post(
    `/quotes/${quoteId}/steps/${stepCode}/process`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

/**
 * Provides the contract status
 * @param {*} param0
 * @returns {*} Api res
 */
// export const getContractStatusFn = async ({ queryKey }) => {
//   // eslint-disable-next-line no-unused-vars
//   const [_, { veosContractID }] = queryKey;
//   const res = await api.get(`/contract-status/${veosContractID}`);
//   return res.data.mouvement;
// };

/**
 * Delete a quote
 * @param {*} quoteId Quote ID
 * @returns {Promise<*>} Api res
 */
export const deleteQuoteFn = async ({ quoteId }) => {
  const res = await api.delete(`/quotes/${quoteId}`);
  return res.data;
};
